import React from "react";
import { FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

function ProjectFilter({onSortChange}) {

  const handleSortChange = (e) => {
    onSortChange(e.target.value);
  };

  return (
    <form>
      <Row>
        <Col>
          <FormGroup>
            {/* Filter Dropdown */}
            <Label for="filterList">Sort By</Label>
            <Input
              type="select"
              name="filterList"
              id="fileterList"
              className="form-control"
              onChange={handleSortChange}
            >
              {/* Sorting Options */}
              <option value="newest">Newest</option>
              <option value="oldest">Oldest</option>
              <option value="title">Title</option>
              <option value="clientName">Client Name</option>
              <option value="currentStage">Current Stage</option>
            </Input>
          </FormGroup>
        </Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </form>
  );
}

export default ProjectFilter;
