import HomeOverview from "views/HomeOverview/HomeOverview";
import Projects from "views/Projects/Projects";
import Websites from "views/Websites/Websites";
import TicketsSupport from "views/TicketsSupport/Tickets";
import ProfileSettings from "views/ProfileSettings/ProfileSettings";
import Logout from "components/Logout/Logout";
import Users from "views/Users/Users"

var routes = [
  {
    path: "/overview",
    name: "Overview",
    icon: "tim-icons icon-chart-pie-36", // Replace with appropriate icons
    component: <HomeOverview />,
    layout: "/admin",
  },
  {
    path: "/projects",
    name: "Projects",
    icon: "tim-icons icon-notes", // Replace with appropriate icons
    component: <Projects />,
    layout: "/admin",
  },
  {
    path: "/websites",
    name: "Websites",
    icon: "tim-icons icon-world", // Replace with appropriate icons
    component: <Websites />,
    layout: "/admin",
  },
  {
    path: "/tickets-support",
    name: "Tickets & Support",
    icon: "tim-icons icon-chat-33", // Replace with appropriate icons
    component: <TicketsSupport />,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "tim-icons icon-single-02", // Replace with appropriate icons
    component: <Users />,
    layout: "/admin",
  },
  {
    path: "/profile_settings",
    name: "Profile Settings",
    icon: "tim-icons icon-settings-gear-63", // Replace with appropriate icons
    component: <ProfileSettings />,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "tim-icons icon-upload", // Replace with appropriate icons
    component: <Logout />,
    layout: "/admin",
  },
];

export default routes;
