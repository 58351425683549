import React from "react";
import AccordionTableItem from "./AccordionTableItem/AccordionTableItem";
import { Table } from "reactstrap";

function AccordionTable({ columns, dataset, DropdownComponent }) {
  return (
    <Table>
      <thead>
        {/* Column Titles */}
        <tr>
          {columns.map((column) => (
            <th className={column.className}>{column.label}</th>
          ))}
          <th className="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        {/* Map Data */}
        {dataset.map((data) => (
          <AccordionTableItem
            data={data}
            columns={columns}
            DropdownComponent={DropdownComponent}
          />
        ))}
      </tbody>
    </Table>
  );
}

export default AccordionTable;
