import React from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Button
} from "reactstrap";
import DataTable from "../../../../../components/DataTable/DataTable";
import {db} from '../../../../../firebase'
import { doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";

function WebsiteListDropdown({ data }) {
  const websiteLabels = [
    { label: "Website Name", accessor: "website_name", className: "text-left" },
    { label: "URL", accessor: "url", className: "text-left" },
    { label: "Project ID", accessor: "project_id", className: "text-left" },
    { label: "Created At", accessor: "created_at", className: "text-center" },
  ];

  const handleDeleteWebsite = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this website?"
    );
    if (confirmDelete) {
      await deleteDoc(doc(db, "websites", data.id));
      console.log("Website deleted successfully");
      // Implement any follow-up action after deletion (e.g., state update, UI feedback)
    }
  };

  return (
    <CardBody>
      <Row>
        <Col>
          <CardText>
            <strong>Website ID:</strong> {data.id}
          </CardText>
          <CardText>
            <strong>Website Name:</strong> {data.website_name}
          </CardText>
          <CardText>
            <strong>URL:</strong>{" "}
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              {data.url}
            </a>
          </CardText>
          <CardText>
            <strong>Created At:</strong> {data.created_at}
          </CardText>
        </Col>
        <Col>
          <CardText>
            <strong>Project ID:</strong> {data.project_id}
          </CardText>
        </Col>
      </Row>
      
      <br />

      <Row>

      <Button
        color="danger"
        onClick={handleDeleteWebsite}
        style={{ marginLeft: "1rem", alignSelf: "flex-end" }}
      >
        Delete Website
      </Button>
      </Row>
    </CardBody>
  );
}

export default WebsiteListDropdown;
