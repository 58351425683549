import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

function WebsiteFilter({ onSortChange }) {
  const handleSortChange = (e) => {
    onSortChange(e.target.value);
  };

  return (
    <form>
      <Row>
        <Col>
          <FormGroup>
            {/* Filter Dropdown */}
            <Label for="filterList">Sort By</Label>
            <Input
              type="select"
              name="filterList"
              id="filterList"
              className="form-control"
              onChange={handleSortChange}
            >
              {/* Updated Sorting Options */}
              <option value="newest">Newest Created</option>
              <option value="oldest">Oldest Created</option>
              <option value="website_name">Website Name</option>
              <option value="url">URL</option>
              {/* Additional sorting criteria can be added here */}
            </Input>
          </FormGroup>
        </Col>
        {/* Removed the Apply button since sorting should be applied directly upon selection */}
        <Col></Col>
        <Col></Col>
        <Col></Col>
      </Row>
    </form>
  );
}

export default WebsiteFilter;
