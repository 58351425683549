import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function TicketSearch({ onSearch }) {
  const [searchSubject, setSearchSubject] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [statusSearch, setStatusSearch] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch({ subject: searchSubject, email: searchEmail, status: statusSearch });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <FormGroup>
            <Label for="searchSubject">Ticket Subject</Label>
            <Input
              type="text"
              name="searchSubject"
              id="searchSubject"
              placeholder="Ticket Subject"
              onChange={e => setSearchSubject(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="searchEmail">User Email</Label>
            <Input
              type="text"
              name="searchEmail"
              id="searchEmail"
              placeholder="User Email"
              onChange={e => setSearchEmail(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
        <FormGroup>
                <Label for="statusSearch">Associated Client</Label>
                <Input
                  type="select"
                  name="statusSearchl"
                  id="statusSearch"
                  className="form-control"
                  onChange={(e) => setStatusSearch(e.target.value)}
                  required
                >
                  <option value="" >Select Status</option>
                  <option value="Open" >Open</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Resolved">Resolved</option>
                  <option value="Closed">Closed</option>
                </Input>
              </FormGroup>
        </Col>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <Button color="info" type="submit">Search</Button>
        </Col>
      </Row>
    </form>
  );
}

export default TicketSearch;
