import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { doc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import { db } from "../../../../../firebase"; // Adjust this import path to your Firebase config file's actual location

function TicketInfo({ data }) {
  const [ticketStatus, setTicketStatus] = useState(data.status);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [websiteUrl, setWebsiteUrl] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const updateTicketStatus = async (newStatus) => {
    const ticketRef = doc(db, "tickets", data.id);
    await updateDoc(ticketRef, {
      status: newStatus,
    });
    setTicketStatus(newStatus); // Update local state to reflect the change
  };

  const handleDeleteTicket = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this ticket?"
    );
    if (confirmDelete) {
      await deleteDoc(doc(db, "tickets", data.id));
      console.log("Ticket deleted successfully");
      // Implement any follow-up action after deletion (e.g., state update, UI feedback)
    }
  };

  useEffect(() => {
    const fetchWebsiteData = async () => {
      const websiteRef = doc(db, "websites", data.website_id);
      const websiteSnap = await getDoc(websiteRef);

      if (websiteSnap.exists()) {
        // Assuming the document has a "url" field
        setWebsiteUrl(websiteSnap.data().url);
      } else {
        console.log("No such website!");
      }
    };

    fetchWebsiteData();
  }, [data.website_id]); // Re-run if data.website_id changes

  return (
    <>
      <CardBody>
        <Row>
          <Col>
            <CardText>
              <strong>Ticket ID:</strong> {data.id}
            </CardText>
            <CardText>
              <strong>Subject:</strong> {data.subject}
            </CardText>
            <CardText>
              <strong>Description:</strong> {data.description}
            </CardText>
            <CardText>
              <strong>Email:</strong> {data.email}
            </CardText>
            <CardText>
              <strong>User ID:</strong> {data.user_id}
            </CardText>
          </Col>
          <Col>
            <CardText>
              <strong>Reason:</strong> {data.reason}
            </CardText>
            <CardText>
              <strong>Website Name:</strong> {data.website_name}
            </CardText>
            <CardText>
              <strong>Website ID:</strong> {data.website_id}
            </CardText>
            <CardText>
              <strong>Website Link:</strong>{" "}
              <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
                {websiteUrl}
              </a>
            </CardText>
            <CardText>
              <strong>Creation Date:</strong> {data.creation_date}
            </CardText>
            <CardText>
              <strong>Status:</strong> {ticketStatus}
            </CardText>
          </Col>
        </Row>

        <br />

        <div
          className="d-flex align-items-center"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle caret color="info">
              Update Status
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => updateTicketStatus("Open")}>
                Open
              </DropdownItem>
              <DropdownItem onClick={() => updateTicketStatus("In Progress")}>
                In Progress
              </DropdownItem>
              <DropdownItem onClick={() => updateTicketStatus("Resolved")}>
                Resolved
              </DropdownItem>
              <DropdownItem onClick={() => updateTicketStatus("Closed")}>
                Closed
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <Button
            color="danger"
            onClick={handleDeleteTicket}
            style={{ marginLeft: "1rem", alignSelf: "flex-end" }}
          >
            Delete Ticket
          </Button>
        </div>
      </CardBody>
    </>
  );
}

export default TicketInfo;
