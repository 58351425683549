import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { db } from "../../../firebase";
import { collection, onSnapshot } from "firebase/firestore";

import TicketSearch from "./TicketSearch/TicketSearch"; // Assuming you adapt ProjectSearch to TicketSearch
import TicketFilter from "./TicketFilter/TicketFilter"; // Assuming you adapt ProjectFilter to TicketFilter
import ModalTable from "components/ModalTable/ModalTable";
import TicketListDropdown from "./TicketModal/TicketInfo/TicketInfo"; // Assuming you adapt ProjectListDropdown to TicketListDropdown
import TicketModal from "./TicketModal/TicketModal";

const ticketLabels = [
  { label: "Subject", accessor: "subject", className: "text-left" },
  { label: "User Email", accessor: "email", className: "text-left" },
  { label: "Status", accessor: "status", className: "text-left" },
  { label: "Creation Date", accessor: "creation_date", className: "text-center" },
  { label: "Resolution Date", accessor: "resolution_date", className: "text-center" },
  { label: "Reason", accessor: "reason", className: "text-left" },
  { label: "Website Name", accessor: "website_name", className: "text-left" },
];

function TicketsOverview() {
  const [sortCriteria, setSortCriteria] = useState("newest");
  const [sortedTickets, setSortedTickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState({ subject: '', email: '', status: '' });

  // Function to update search criteria
  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "tickets"), (snapshot) => {
      const ticketsData = snapshot.docs.map((doc) => {
        let data = doc.data();
        data.creation_date = data.creation_date ? data.creation_date.toDate().toLocaleDateString("en-US") : "N/A";
        data.resolution_date = data.resolution_date ? data.resolution_date.toDate().toLocaleDateString("en-US") : "Unresolved";
        return { id: doc.id, ...data };
      });

      // Filter and sort based on search and sort criteria
      const filteredAndSortedTickets = ticketsData.filter(ticket => {
        return (!searchCriteria.subject || ticket.subject.toLowerCase().includes(searchCriteria.subject.toLowerCase())) &&
               (!searchCriteria.email || ticket.email.toLowerCase().includes(searchCriteria.email.toLowerCase())) &&
               (!searchCriteria.status || ticket.status === searchCriteria.status);
      }).sort((a, b) => {
        // Simplified sorting logic for demonstration
        if (sortCriteria === 'newest') {
          return new Date(b.creation_date) - new Date(a.creation_date);
        } else if (sortCriteria === 'oldest') {
          return new Date(a.creation_date) - new Date(b.creation_date);
        }
        return 0;
      });

      setSortedTickets(filteredAndSortedTickets);
    });

    return () => unsubscribe();
  }, [searchCriteria, sortCriteria]);

  return (
    <Card>
      <CardHeader>
        <TicketSearch onSearch={handleSearch}/>
        <TicketFilter onSortChange={setSortCriteria} />
      </CardHeader>

      {sortedTickets.length > 0 ? (
        <CardBody>
          <CardTitle>
            <h2>Ticket List Overview</h2>
          </CardTitle>
          <ModalTable
            columns={ticketLabels}
            dataset={sortedTickets}
            ModalElement={TicketModal}
          />
        </CardBody>
      ) : (
        <CardBody style={{ margin: "1%", textAlign: "center" }}>
          <h5>No Tickets Found</h5>
        </CardBody>
      )}
    </Card>
  );
}

export default TicketsOverview;
