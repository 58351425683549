// ForgotPasswordForm.js
import React, { useState } from 'react';
import { TextField, Button, Box, Alert, Typography } from '@mui/material';

const ForgotPasswordForm = ({ onBackClick }) => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [emailError, setEmailError] = useState('');

  const isEmailValid = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isEmailValid(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    setEmailError('');
    setSubmitted(true);
    // Add logic to handle forgot password functionality
  };

  return (
    <Box sx={{
      maxWidth: 400,
      mx: 'auto',
      p: 3,
      boxShadow: 3,
      borderRadius: 2,
      border: '2px solid cornflowerblue',
      bgcolor: '#222222',
      textAlign: 'center',
      '& .MuiTextField-root': { 
        mb: 2,
        '& label': { 
          color: 'cornflowerblue',
          '&.Mui-error': { 
            color: 'red' 
          },
        },
        '& .MuiInputBase-input': { 
          color: 'white',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:hover': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:active': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
        },
        '& .MuiInput-underline:before': { borderBottomColor: 'cornflowerblue' },
        '& .MuiInput-underline:after': { borderBottomColor: 'cornflowerblue' },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'cornflowerblue' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: 'cornflowerblue' },
          '&:hover fieldset': { borderColor: 'cornflowerblue' },
          '&.Mui-focused fieldset': { borderColor: 'cornflowerblue' },
          '&.Mui-error fieldset': { borderColor: 'red' },
        },
        '& .MuiIconButton-root': { color: 'cornflowerblue' },
        '& .Mui-error .MuiIconButton-root': { color: 'red' },
      },
      '& .MuiButton-root': {
        bgcolor: 'cornflowerblue',
        color: 'white',
        transition: 'background-color 0.38s ease, transform 0.38s ease', // Transition effect for background-color and transform
        '&:hover': { 
          bgcolor: 'rgba(100, 149, 237, 0.85)', // Slightly lighter cornflower blue on hover
          transform: 'translateY(-3px)'
        },
        '&:active': { 
          transform: 'translateY(0)'
        },
      },
      '& .MuiAlert-root': { mb: 2 },
    }}>
      <Typography variant="h5" sx={{ color: 'cornflowerblue', mb: 3 }}>Reset Password</Typography>
      <Typography variant="body1" sx={{ color: 'white', mb: 3 }}>
        Enter your email address below, and we'll send you a link to reset your password.
      </Typography>

      {!submitted ? (
        <form onSubmit={handleSubmit} noValidate>
          <TextField
            fullWidth
            required
            label="Email"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <Button fullWidth type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Submit
          </Button>
        </form>
      ) : (
        <Alert severity="info" sx={{ mb: 2 }}>
          If an account with the entered email exists, we will send an email to reset your password.
        </Alert>
      )}

      <Button fullWidth variant="text" onClick={onBackClick} sx={{ color: 'white' }}>
        Back to Login
      </Button>
    </Box>
  );
};

export default ForgotPasswordForm;
