import React, { useRef, useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import Chart from "chart.js/auto";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Table,
  ButtonGroup,
  setRSelected,
} from "reactstrap";
import HomeGraph from "./HomeGraph/HomeGraph";
import DataTable from "components/DataTable/DataTable";
const chartColor = "#FFFFFF";

const projectColumns = [
  { label: "Name", accessor: "project_name", className: "text-left" },
  { label: "Status", accessor: "status", className: "text-left" },
  { label: "Created", accessor: "start_date", className: "text-center" },
];

const ticketColumns = [
  { label: "Name", accessor: "reason", className: "text-left" },
  { label: "Client Email", accessor: "email", className: "text-left" },
  { label: "Status", accessor: "status", className: "text-center" },
];

const formatDate = (timestamp) => {
  // Check if timestamp exists and has toDate function
  if (!timestamp || typeof timestamp.toDate !== "function") {
    // Handle the case where timestamp might already be in another format or undefined
    return "";
  }

  const date = timestamp.toDate();
  return (
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "/" +
    date.getDate().toString().padStart(2, "0") +
    "/" +
    date.getFullYear().toString()
  );
};

function HomeOverview() {
  const [tickets, setTickets] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const oneWeekAgo = Timestamp.fromDate(
      new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
    );

    const fetchTickets = async () => {
      const q = query(
        collection(db, "tickets"),
        where("creation_date", ">", oneWeekAgo)
      );
      const querySnapshot = await getDocs(q);
      const fetchedTickets = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          creation_date: formatDate(data.creation_date), // Format the timestamp
          resolution_date: formatDate(data.resolution_date),
        };
      });
      setTickets(fetchedTickets);
    };

    const fetchProjects = async () => {
      const q = query(
        collection(db, "projects"),
        where("start_date", ">", oneWeekAgo)
      );
      const querySnapshot = await getDocs(q);
      const fetchedProjects = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          start_date: formatDate(data.start_date), // Format the timestamp
          end_date: formatDate(data.end_date),
        };
      });
      setProjects(fetchedProjects);
    };

    fetchTickets();
    fetchProjects();
  }, []);

  return (
    <div className="content">
      <HomeGraph />

      <div style={{ display: "flex", gap: "1%" }}>
        <Card style={{ width: "50%" }}>
          <CardHeader>
            <CardTitle>
              <h2>New Tickets</h2>
            </CardTitle>
          </CardHeader>

          {tickets.length > 0 ? (
            <CardBody>
              <DataTable columns={ticketColumns} dataset={tickets} />
            </CardBody>
          ) : (
            <CardBody style={{ margin: "1%", textAlign: "center" }}>
              <h5>No new tickets.</h5>
            </CardBody>
          )}
        </Card>

        <Card style={{ width: "50%" }}>
          <CardHeader>
            <CardTitle>
              <h2>New Projects</h2>
            </CardTitle>
          </CardHeader>

          {projects.length > 0 ? (
            <CardBody>
              <DataTable columns={projectColumns} dataset={projects} />
            </CardBody>
          ) : (
            <CardBody style={{ margin: "1%", textAlign: "center" }}>
              <h5>No new projects.</h5>
            </CardBody>
          )}
        </Card>
      </div>
    </div>
  );
}

export default HomeOverview;
