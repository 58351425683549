import React, { useState } from "react";
import { FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

function ProjectSearch({ onSearch }) {
  const [searchName, setSearchName] = useState('');
  const [searchClient, setSearchClient] = useState('');
  const [statusSearch, setStatusSearch] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch({ name: searchName, client: searchClient, status: statusSearch });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          {/* Name Search */}
          <FormGroup>
            <Label for="searchName">Project Name</Label>
            <Input
              type="text"
              name="searchName"
              id="searchName"
              placeholder="Project Name"
              className="form-control"
              onChange={e => setSearchName(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          {/* Client Search */}
          <FormGroup>
            <Label for="searchClient">Client Name</Label>
            <Input
              type="text"
              name="searchClient"
              id="searchClient"
              placeholder="Client Name"
              onChange={e => setSearchClient(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col>
          {/* Status Search */}
          <FormGroup>
            <Label for="statusSearch">Status</Label>
            <Input
              type="select"
              name="statusSearch"
              id="statusSearch"
              className="form-control"
              onChange={e => setStatusSearch(e.target.value)}
            >
              {/* Search Options */}
              <option value="">Select Status</option>
              <option value="Discussion & Consultation">Discussion & Consultation</option>
              <option value="Design">Design</option>
              <option value="Revise & Approve">Revise & Approve</option>
              <option value="Development">Development</option>
              <option value="Deployment & Final Approval">Deployment & Final Approval</option>
            </Input>
          </FormGroup>
        </Col>
        <Col style={{ display: "flex", alignItems: "center" }}>
          {/* Submit Button */}
          <Button color="info" type="submit">
            Search
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default ProjectSearch;
