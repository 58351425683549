import React, { useRef, useEffect, useState } from "react";
import Chart from "chart.js/auto";
import chartConfig from "./chartConfig.json";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardHeader,
  CardText,
  Button,
  Table,
  ButtonGroup,
  setRSelected,
  Row,
  Col,
} from "reactstrap";
const chartColor = "#525f7f";

function HomeGraph() {
  const [rSelected, setRSelected] = useState(null);

  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");

      // Create the gradient stroke (line color)
      var gradientStroke = ctx.createLinearGradient(0, 0, 0, 300);
      gradientStroke.addColorStop(0, "#1d8cf8");
      gradientStroke.addColorStop(1, "#1d8cf8"); // You can adjust this color

      // Create the matching gradient fill
      var gradientFill = ctx.createLinearGradient(0, 0, 0, 300);
      gradientFill.addColorStop(0, "rgba(29, 140, 248, 0.2)");
      gradientFill.addColorStop(1, "rgba(29, 140, 248, 0)");

      // Apply the gradient stroke and fill to the dataset
      chartConfig.data.datasets.forEach((dataset) => {
        dataset.borderColor = gradientStroke;
        dataset.backgroundColor = gradientFill;
      });

      // Instantiate the chart with the config
      const chartInstance = new Chart(ctx, chartConfig);
    }
  }, []);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle>
              <h2>Performance</h2>
            </CardTitle>
          </Col>
          <Col className="text-right">
            <ButtonGroup>
              <Button
                className="btn-simple"
                color="info"
                outline
                onClick={() => setRSelected(1)}
                active={rSelected === 1}
              >
                Radio 1
              </Button>
              <Button
                className="btn-simple"
                color="info"
                outline
                onClick={() => setRSelected(2)}
                active={rSelected === 2}
              >
                Radio 2
              </Button>
              <Button
                className="btn-simple"
                color="info"
                outline
                onClick={() => setRSelected(3)}
                active={rSelected === 3}
              >
                Radio 3
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div style={{ height: "300px", width: "100%" }}>
          <canvas ref={chartRef} style={{ width: "100%" }} />
        </div>
      </CardBody>
    </Card>
  );
}

export default HomeGraph;
