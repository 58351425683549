import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const CreateWebsite = () => {
  const [websiteName, setWebsiteName] = useState("");
  const [url, setUrl] = useState("");
  const [projectId, setProjectId] = useState("");
  const [projects, setProjects] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      const querySnapshot = await getDocs(collection(db, "projects"));
      const fetchedProjects = querySnapshot.docs.map(doc => ({
        label: doc.data().project_name,
        value: doc.id
      }));
      setProjects(fetchedProjects);
    };

    fetchProjects();
  }, []);

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleProjectSelect = (value, label) => {
    setProjectId(value);
    // Additional functionality to display selected project name if needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "websites"), {
        website_name: websiteName,
        url: url,
        project_id: projectId,
        created_at: serverTimestamp()
      });
      // Reset form or provide user feedback
      console.log("Website added successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="websiteName">Website Name</Label>
                <Input
                  type="text"
                  name="websiteName"
                  id="websiteName"
                  placeholder="Website Name"
                  value={websiteName}
                  onChange={(e) => setWebsiteName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="url">Website URL</Label>
                <Input
                  type="text"
                  name="url"
                  id="url"
                  placeholder="Website URL"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
            <FormGroup>
                <Label for="project">Associated Project</Label>
                <Input
                  type="select"
                  name="project"
                  id="project"
                  className="form-control"
                  onChange={(e) => handleProjectSelect(e.target.value)}
                  required
                >
                  <option value="" >Select a Client</option>
                  {projects.map(project => (
                    <option key={project.value} value={project.value}>{project.label}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button color="info" type="submit">Create New Website</Button>
        </form>
      </CardBody>
    </Card>
  );
};

export default CreateWebsite;
