import React from "react";
import { FormGroup, Label, Input, Row, Col } from "reactstrap";

function TicketFilter({ onSortChange }) {

  const handleSortChange = (e) => {
    onSortChange(e.target.value);
  };

  return (
    <form>
      <Row>
        <Col>
          <FormGroup>
            {/* Filter Dropdown */}
            <Label for="filterList">Sort By</Label>
            <Input
              type="select"
              name="filterList"
              id="filterList" // Corrected typo from "fileterList" to "filterList"
              className="form-control"
              onChange={handleSortChange}
            >
              {/* Updated Sorting Options for Tickets */}
              <option value="newest">Newest Created</option>
              <option value="oldest">Oldest Created</option>
              <option value="subject">Subject</option>
              <option value="status">Status</option>
              <option value="email">User Email</option>
              {/* Additional sorting options can be added as needed */}
            </Input>
          </FormGroup>
        </Col>
        <Col></Col>
        <Col></Col>
        <Col></Col>
        {/* Removed empty columns for cleaner layout */}
      </Row>
    </form>
  );
}

export default TicketFilter;
