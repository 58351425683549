import React, { useState } from 'react';
import LoginForm from './LoginForm';
import ForgotPassword from './ForgotPassword'; // Corrected component name
import backgroundImage from '../../assets/img/bg.jpg'; // Import the image

function Login() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const toggleForm = () => {
    setShowForgotPassword(!showForgotPassword);
  };

  const FormContainerStyle = {
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#222222'
  };

  return (
    <div style={FormContainerStyle}>
      {showForgotPassword ? (
        <ForgotPassword onBackClick={toggleForm} />
      ) : (
        <LoginForm onForgotPasswordClick={toggleForm} />
      )}
    </div>
  );
}

export default Login;
