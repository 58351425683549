import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import DashboardLayout from "layouts/dashboard.js";
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute';

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

import "assets/scss/black-dashboard-react.scss";

import Login from 'components/Login/Login';
import Logout from 'components/Logout/Logout';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeContextWrapper>
    <BackgroundColorWrapper>
      <BrowserRouter>
        <Routes>
        
          <Route path="/login" element={<Login />}/>
          <Route path="/logout" element={<Logout />} />

          //Protected Route Elements are for checking if the user is logged in
          // if they aren't the code detects this and sends them back to the login with ever returning the child elements
          <Route path='/*' element={ <ProtectedRoute> <DashboardLayout/> </ProtectedRoute>}/>
          <Route path="*" element={<ProtectedRoute> <Navigate to="/overview" replace /> </ProtectedRoute>} />

        </Routes>
      </BrowserRouter>
    </BackgroundColorWrapper>
  </ThemeContextWrapper>
);


