import React, { useEffect, useState } from "react";

import CreateTicket from "./CreateTicket/CreateTicket";
import TicketOverview from "./TicketOverview/TicketOverview";

function Projects() {

  return (
    <div className="content">
      <CreateTicket />
      <TicketOverview/>
    </div>
  );
}

export default Projects;