import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import TicketInfo from "./TicketInfo/TicketInfo";

function TicketModal({ data }) {
  // State hook for managing modal visibility
  const [modalLarge, setModalLarge] = useState(false);

  // Function to toggle modal's visibility
  const toggleModalLarge = () => setModalLarge(!modalLarge);

  const closeBtn = (
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      aria-hidden="true"
      onClick={toggleModalLarge}
    >
      <i class="tim-icons icon-simple-remove"></i>
    </button>
  );

  return (
    <>
      {/* Button to trigger the modal */}
      <Button color="info" onClick={toggleModalLarge}>
        View More
      </Button>

      {/* Large Modal */}
      <Modal
        isOpen={modalLarge}
        toggle={toggleModalLarge}
        size="lg"
        style={{ border: "5px solid #6495ED", borderRadius: "10px" }}
      >
        <Card style={{ height: "100%", marginBottom: "0" }}>
            <ModalHeader toggle={toggleModalLarge} close={closeBtn} style={{borderBottom: "1px solid #6495ED"}}>
              <h3>Ticket Info</h3>
          </ModalHeader>

          <CardBody>
            {/* Your modal content here */}
            <TicketInfo data={data} />
          </CardBody>
        </Card>
      </Modal>
    </>
  );
}

export default TicketModal;
