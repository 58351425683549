import React, { useEffect, useState } from "react";

import CreateWebsite from "./CreateWebsite/CreateWebsite";
import WebsiteOverview from "./WebsiteOverview/WebsiteOverview";

function Websites() {

  return (
    <div className="content">
      <CreateWebsite/>
      <WebsiteOverview/>
    </div>
  );
}

export default Websites;
