import React from "react";
import { Table } from "reactstrap";

function ModalTable({ columns, dataset, ModalElement }) {
  return (
    <Table>
      <thead>
        {/* Column Titles */}
        <tr>
          {columns.map((column) => (
            <th className={column.className}>{column.label}</th>
          ))}
            <th className="text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        {/* Map Data */}
        {dataset.map((data) => (
          <tr>
          {/* Table Data */}
          {columns.map((column) => (
            <td className={column.className}>{data[column.accessor]}</td>
          ))}
            <td className="text-right"><ModalElement data={data}/></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ModalTable;