import React, { useEffect, useState } from "react";

import CreateProject from "./CreateProject/CreateProject";
import ProjectOverview from "./ProjectOverview/ProjectOverview";

function Projects() {

  return (
    <div className="content">
      <CreateProject/>
      <ProjectOverview/>
    </div>
  );
}

export default Projects;

