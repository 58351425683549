import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { db } from "../../../firebase";
import { collection, onSnapshot } from "firebase/firestore";

import WebsiteSearch from "./WebsiteSearch/WebsiteSearch";
import WebsiteFilter from "./WebsiteFilter/WebsiteFilter";
import AccordionTable from "components/AccordionTable/AccordionTable";
import WebsiteModal from "./WebsiteModal/WebsiteModal";
import ModalTable from "components/ModalTable/ModalTable";
import { Modal } from "@mui/material";

const websiteLabels = [
  { label: "Website Name", accessor: "website_name", className: "text-left" },
  { label: "URL", accessor: "url", className: "text-left" },
  { label: "Project ID", accessor: "project_id", className: "text-left" },
  { label: "Created At", accessor: "created_at", className: "text-center" },
  { label: "Document ID", accessor: "id", className: "text-center" },
];

function WebsiteOverview() {
  const [sortCriteria, setSortCriteria] = useState("newest");
  const [searchCriteria, setSearchCriteria] = useState({ name: '', url: '' });
  const [sortedWebsites, setSortedWebsites] = useState([]);
  const [websites, setWebsites] = useState([]);

  // Function to update search criteria
  const handleSearch = (criteria) => {
    setSearchCriteria(criteria);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "websites"), (snapshot) => {
      const websitesData = snapshot.docs.map((doc) => {
        let data = doc.data();
        if (data.created_at && typeof data.created_at.toDate === "function") {
          data.created_at = data.created_at.toDate().toLocaleDateString("en-US");
        }
        return { id: doc.id, ...data };
      });

      // Filter based on search criteria
      const filteredWebsites = websitesData.filter(website => {
        return (!searchCriteria.name || website.website_name.toLowerCase().includes(searchCriteria.name.toLowerCase())) &&
               (!searchCriteria.url || website.url.toLowerCase().includes(searchCriteria.url.toLowerCase()));
      });

      setWebsites(filteredWebsites);
    });

    return () => unsubscribe();
  }, [searchCriteria]);

  useEffect(() => {
    const sortedArray = [...websites].sort((a, b) => {
      if (sortCriteria === 'newest') {
        return new Date(b.created_at) - new Date(a.created_at);
      } else if (sortCriteria === 'oldest') {
        return new Date(a.created_at) - new Date(b.created_at);
      } else if (sortCriteria === 'website_name') {
        return a.website_name.localeCompare(b.website_name);
      } else if (sortCriteria === 'url') {
        return a.url.localeCompare(b.url);
      }
      return 0;
    });

    setSortedWebsites(sortedArray);
  }, [websites, sortCriteria]);

  return (
    <Card>
      <CardHeader>
        <WebsiteSearch onSearch={handleSearch}/>
        <WebsiteFilter onSortChange={setSortCriteria} />
      </CardHeader>

      {sortedWebsites.length > 0 ? (
        <CardBody style={{minHeight: "1000px"}}>
          <CardTitle>
            <h2>Website List Overview</h2>
          </CardTitle>
          <ModalTable
            columns={websiteLabels}
            dataset={sortedWebsites}
            ModalElement={WebsiteModal}
          />
        </CardBody>
      ) : (
        <CardBody style={{ margin: "1%", textAlign: "center", height: "1000px"}}>
          <h5>No Websites Found</h5>
        </CardBody>
      )}
    </Card>
  );
}

export default WebsiteOverview;
