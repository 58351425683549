import React, {useState, useEffect} from "react";
import {
  Card,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
  Button,
} from "reactstrap";
import StatusBar from "./StatusBar/StatusBar";
import DataTable from "../../../../components/DataTable/DataTable";
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';

import { db } from '../../../../firebase'; // Adjust the import path to your firebase config

function ProjectListDropdown({ data }) {
  const websiteLabels = [
    { label: "Name", accessor: "website_name", className: "text-left" },
    { label: "URL", accessor: "url", className: "text-left" },
    { label: "Start", accessor: "created_at", className: "text-center" },
  ];

  const [status, setStatus] = useState(data.status); // Assuming 'data.status' holds the current status

  const updateProjectStatus = async (projectId, newStatus) => {
    const projectRef = doc(db, 'projects', projectId);
    await updateDoc(projectRef, {
      status: newStatus
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProjectStatus(data.id, status);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const [websites, setWebsites] = useState([]); // State to store fetched websites

  useEffect(() => {
    const fetchWebsites = async () => {
      const q = query(collection(db, "websites"), where("project_id", "==", data.id));
      const querySnapshot = await getDocs(q);
      const fetchedWebsites = querySnapshot.docs.map(doc => {
        const data = doc.data();
        // Convert the timestamp to a Date object
        const createdAt = data.created_at ? data.created_at.toDate() : new Date();
        // Format the date to mm/dd/yyyy
        const formattedDate = (createdAt.getMonth() + 1).toString().padStart(2, '0') + '/'
        + createdAt.getDate().toString().padStart(2, '0') + '/'
        + createdAt.getFullYear().toString();
        
        return {
          ...data,
          id: doc.id, // Add doc ID if needed
          created_at: formattedDate, // Replace the timestamp with the formatted date
        };
      });
      setWebsites(fetchedWebsites);
    };
  
    fetchWebsites();
  }, [data.id]); // Re-fetch when data.id changes
  

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#1e1e2f",
        padding: "1%",
      }}
    >
      <Row>
        <Col>
          <Card style={{ height: "90%" }}>
            <CardHeader>
              <CardTitle>
                <h3>Description</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <CardText>{data.description}</CardText>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: "90%" }}>
            <CardHeader>
              <CardTitle>
                <h3>Status</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <StatusBar data={data} />
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Row>
                    <Col>
                      <Label for="statusSearch">Status</Label>
                      <Input
                        type="select"
                        name="statusSearch"
                        id="statusSearch"
                        className="form-control"
                        value={data.status}
                        onChange={handleStatusChange}
                      >
                        {/* Status Options */}
                        <option>Discussion & Consultation</option>
                        <option>Design</option>
                        <option>Revise & Approve</option>
                        <option>Development</option>
                        <option>Deployment & Final Approval</option>
                        <option>Complete Project</option>
                      </Input>
                    </Col>
                    <Col style={{ display: "flex", alignItems: "center" }}>
                      <Button color="info" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        {websites.length > 0 ? (
          <Card style={{ margin: "1%" }}>
            <CardHeader>
              <CardTitle>
                <h3>Websites</h3>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <DataTable columns={websiteLabels} dataset={websites} />
            </CardBody>
          </Card>
        ) : (
          <Card style={{ margin: "1%", textAlign: "center" }}>
            <CardBody>
              <h5>No websites associated with this Project.</h5>
              {/* You can place more content here, such as instructions to add a website or related graphics */}
            </CardBody>
          </Card>
        )}
      </Row>
    </div>
  );
}

export default ProjectListDropdown;
