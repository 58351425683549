// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJ5dbKA8FwHlszQ3rD4yVhRkVfFeDA7dc",
  authDomain: "cleanweb-app.firebaseapp.com",
  projectId: "cleanweb-app",
  storageBucket: "cleanweb-app.appspot.com",
  messagingSenderId: "39024508775",
  appId: "1:39024508775:web:2737e2fff272f50ca5b9c2",
  measurementId: "G-RKTL5LNZWS",
  dataBaseURL: "https://cleanweb-app-default-rtdb.firebaseio.com"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and export it
export const auth = getAuth(app);

// Initialize Firestore and export it
const db = getFirestore(app);

export {app, db, EmailAuthProvider, GoogleAuthProvider};