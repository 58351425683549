import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { query, where, getDocs, collection, addDoc, serverTimestamp } from "firebase/firestore";

import{
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

function CreateProject() {
  const [projectName, setProjectName] = useState("");
  const [clientEmail, setClientEmail] = useState(""); // Assuming you want to store client's email
  const [description, setDescription] = useState("");
  const [clients, setClients] = useState([]); // To store fetched clients

  useEffect(() => {
    const fetchClients = async () => {
      const usersRef = collection(db, "users");
      const q = query(usersRef, where("role", "==", "client"));
      const querySnapshot = await getDocs(q);
      const fetchedClients = [];
      querySnapshot.forEach(doc => {
        // Assuming you want to use the client's email and ID
        fetchedClients.push({ label: doc.data().email, value: doc.id });
      });
      setClients(fetchedClients);
    };

    fetchClients();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Query for the user by email
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("email", "==", clientEmail)); // Adjust "email" to your user document's actual field name
  
    try {
      const querySnapshot = await getDocs(q);
      let userId = null;
      querySnapshot.forEach((doc) => {
        // Assuming each email address uniquely identifies a user
        userId = doc.id; // Get the user ID
      });
  
      if (userId) {
        await addDoc(collection(db, "projects"), {
          project_name: projectName,
          clientUID: userId, // Use userId here
          description: description,
          start_date: serverTimestamp(),
          end_date: "Still Open",
          status: "Discussion & Consultation"

          // Add other project details here
        });
        // Reset form or provide user feedback
      } else {
        console.log("No user found with that email");
      }
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };
  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="projectName">Project Name</Label>
                <Input
                  type="text"
                  name="projectName"
                  id="projectName"
                  placeholder="Project Name"
                  onChange={(e) => setProjectName(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                <Label for="clientEmail">Associated Client</Label>
                <Input
                  type="select"
                  name="clientEmail"
                  id="clientEmail"
                  className="form-control"
                  onChange={(e) => setClientEmail(e.target.value)}
                  required
                >
                  <option value="" >Select a Client</option>
                  {clients.map(client => (
                    <option key={client.value} value={client.value}>{client.label}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              placeholder="Description"
              style={{ paddingLeft: "10px" }}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            <Button color="info" type="submit">
              Create New Project
            </Button>
          </FormGroup>
        </form>
      </CardBody>
    </Card>
  );
}

export default CreateProject;
