import React, { useState } from 'react';
import { auth, GoogleAuthProvider, db } from '../../firebase';
import { signInWithEmailAndPassword, signInWithPopup, getAuth, onAuthStateChanged  } from "firebase/auth";
import { TextField, Button, InputAdornment, IconButton, Box, Grid, Divider, Alert } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import GoogleSignInButton from './GoogleLoginButton';
import { doc, getDoc } from "firebase/firestore";

const LoginForm = ({ onForgotPasswordClick }) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [firebaseError, setFirebaseError] = useState('');
  const [touched, setTouched] = useState({ email: false, password: false });

  const checkUserRole = async (uid) => {
    const userRef = doc(db, "users", uid);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists() && userSnap.data().role === "admin") {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched({ email: true, password: true });
    if (!email || !password) {
      setFirebaseError('Email and password are required.');
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const isAdmin = await checkUserRole(userCredential.user.uid);
      if (isAdmin) {
        // Redirect on successful login if admin
        window.location.href = "/overview";
      } else {
        setFirebaseError("Access denied. Only admins can log in.");
      }
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setFirebaseError("Invalid email format. Please check your email.");
      } else if (error.code === "auth/wrong-password" || error.code === "auth/user-not-found") {
        setFirebaseError("Invalid credentials. Please try again.");
      } else {
        setFirebaseError("An error occurred. Please try again.");
      }
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const isAdmin = await checkUserRole(result.user.uid);
      if (isAdmin) {
        // Redirect on successful login if admin
        window.location.href = "/overview";
      } else {
        setFirebaseError("Access denied. Only admins can log in.");
      }
    } catch (error) {
      setFirebaseError(error.message);
    }
  };

  const handleBlur = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  return (
    <Box sx={{
      maxWidth: 400,
      mx: 'auto',
      p: 3,
      boxShadow: 3,
      borderRadius: 2,
      border: '2px solid cornflowerblue',
      bgcolor: 'rgba(34, 34, 34, 0.80)',
      '& .MuiTextField-root': { 
        mb: 2,
        '& label': { 
          color: 'cornflowerblue',
          '&.Mui-error': { 
            color: 'red' 
          },
        },
        '& .MuiInputBase-input': { 
          color: 'white',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:hover': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:focus': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
          '&:-webkit-autofill:active': {
            WebkitBoxShadow: '0 0 0 1000px #222222 inset',
            WebkitTextFillColor: 'white',
          },
        },
        '& .MuiInput-underline:before': { borderBottomColor: 'cornflowerblue' },
        '& .MuiInput-underline:after': { borderBottomColor: 'cornflowerblue' },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'cornflowerblue' },
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: 'cornflowerblue' },
          '&:hover fieldset': { borderColor: 'cornflowerblue' },
          '&.Mui-focused fieldset': { borderColor: 'cornflowerblue' },
          '&.Mui-error fieldset': { borderColor: 'red' },
        },
        '& .MuiIconButton-root': { color: 'cornflowerblue' },
        '& .Mui-error .MuiIconButton-root': { color: 'red' },
      },
      '& .MuiButton-root': {
        bgcolor: 'cornflowerblue',
        color: 'white',
        transition: 'background-color 0.38s ease, transform 0.38s ease', // Transition effect for background-color and transform
        '&:hover': { 
          bgcolor: 'rgba(100, 149, 237, 0.85)', // Slightly lighter cornflower blue on hover
          transform: 'translateY(-3px)' 
        },
        '&:active': { 
          transform: 'translateY(0)' 
        },
      },
      // Specific styling for the "Forgot Password" button
      '& .forgot-password-button': {
        bgcolor: 'transparent', // Transparent background by default
        color: 'white', // White text color
        transition: 'background-color 0.5s ease, transform 0.38 ease', // Smooth transition for background color
        '&:hover': {
          bgcolor: 'cornflowerblue', // Cornflower blue background on hover
        },
      },
      '& .MuiAlert-root': { mb: 2 },
      '& .MuiDivider-root': { bgcolor: 'transparent' },
    }}>

      {/* <h2 style={{color: "cornflowerblue", textAlign: "center"}}>LOGIN</h2> */}


      <form onSubmit={handleSubmit} noValidate>
        <TextField
          fullWidth
          required
          label="Email"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={() => handleBlur('email')}
          error={touched.email && !email}
          helperText={touched.email && !email ? "Email is required" : ""}
        />
        <TextField
          fullWidth
          required
          type={showPassword ? 'text' : 'password'}
          label="Password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={() => handleBlur('password')}
          error={touched.password && !password}
          helperText={touched.password && !password ? "Password is required" : ""}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  sx={{ color: 'cornflowerblue' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {firebaseError && <Alert severity="error">{firebaseError}</Alert>}
        <Button
          fullWidth type="submit" 
          variant="contained" 
          sx={{ 
            mt: 3, 
            mb: 2,
            borderRadius: '20px', // Adjust border radius
            paddingY: '12px'
          }}>
          LOGIN
        </Button>
        <Button 
          fullWidth 
          variant="text"
          className="forgot-password-button"
          onClick={onForgotPasswordClick}
          sx={{ 
            mb: 2, 
            color: 'white',
            '&:hover': { 
              bgcolor: 'transparent', 
              color: 'white',
              transform: 'translateY(-3px)'
            },
            '&:active': {
              transform: 'translateY(0px)'
            },
            borderRadius: '20px', // Adjust border radius
            paddingY: '12px' // Adjust top and bottom padding
          }}
        >
          Forgot Password?
        </Button>
        <Divider sx={{ my: 3, color: 'cornflowerblue', '&::before, &::after': { borderColor: 'cornflowerblue' } }}>OR</Divider>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ pr: 1 }}>
            <GoogleSignInButton onClick={handleGoogleLogin} />
          </Grid>
          <Grid item xs={6} sx={{ pl: 1 }}>
            {/* <Button fullWidth variant="outlined" sx={{ bgcolor: 'white', color: 'cornflowerblue', padding: '10px 20px' }}>
              Facebook Login
            </Button> */}
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LoginForm;