import React from "react"

import UserOverview from "./UserOverview/UserOverview"


const Users = () => {
    return(
        <div className="content">
            <UserOverview />
        </div>
    )
}

export default Users