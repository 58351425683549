import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

// core components
import DashboardNavbar from "../components/Navbars/DashboardNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

// logo images
import titleLogoLg from '../assets/clean-web-logos/title-logo-lg.png';
import HomeOverview from "views/HomeOverview/HomeOverview.js";

import routes from "routes.js";

function Dashboard(props) {
  const location = useLocation();

  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      console.log(prop);
      if (prop.layout === "/admin") {
        console.log("Route object created");
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <React.Fragment>
      <div className="wrapper">

        <Sidebar
          routes={routes}
          toggleSidebar={toggleSidebar}
        />


        <div className="main-panel" data="blue">
          <Routes>
            {getRoutes(routes)}
            <Route
              path="/"
              element={<Navigate to="/admin/home-overview" replace />}
            />
          </Routes>

        </div>
      </div>
    </React.Fragment>
  );
}

export default Dashboard;
