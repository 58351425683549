import React, { useState } from "react";
import { FormGroup, Label, Input, Button, Row, Col } from "reactstrap";

function WebsiteSearch({ onSearch }) {
  const [searchName, setSearchName] = useState('');
  const [searchURL, setSearchURL] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Pass the search criteria back to the parent component
    onSearch({ name: searchName, url: searchURL });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col>
          {/* Website Name Search */}
          <FormGroup>
            <Label for="searchName">Website Name</Label>
            <Input
              type="text"
              name="searchName"
              id="searchName"
              placeholder="Website Name"
              value={searchName}
              onChange={e => setSearchName(e.target.value)} // Handle input change
              className="form-control"
            />
          </FormGroup>
        </Col>
        <Col>
          {/* Website URL Search */}
          <FormGroup>
            <Label for="searchURL">Website URL</Label>
            <Input
              type="text"
              name="searchURL"
              id="searchURL"
              placeholder="Website URL"
              value={searchURL}
              onChange={e => setSearchURL(e.target.value)} // Handle input change
            />
          </FormGroup>
        </Col>
        <Col style={{ display: "flex", alignItems: "center" }}>
          {/* Search Button */}
          <Button color="info" type="submit">
            Search
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default WebsiteSearch;
