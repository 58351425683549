import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { db } from "../../../firebase";
import { collection, onSnapshot, doc, getDoc } from "firebase/firestore";

import ProjectSearch from "./ProjectSearch/ProjectSearch";
import ProjectFilter from "./ProjectFilter/ProjectFilter";
import AccordionTable from "components/AccordionTable/AccordionTable";
import ProjectListDropdown from "./ProjectListDropdown/ProjectListDropdown";

const projectLabels = [
  { label: "Name", accessor: "project_name", className: "text-left" },
  { label: "Client Name", accessor: "clientName", className: "text-left" },
  { label: "Status", accessor: "status", className: "text-left" },
  { label: "Created", accessor: "start_date", className: "text-center" },
  { label: "Resolved", accessor: "end_date", className: "text-center" },
];

function ProjectOverview() {
  const [sortCriteria, setSortCriteria] = useState("newest");
  const [sortedProjects, setSortedProjects] = useState([]);
  

  const handleSortChange = (criteria) => {
    setSortCriteria(criteria);
  };

  const [projects, setProjects] = useState([]);

  const [searchCriteria, setSearchCriteria] = useState({ name: '', client: '', status: '' });

// Function to update search criteria
const handleSearch = (criteria) => {
  setSearchCriteria(criteria);
};

const statusOrder = {
  "Discussion & Consultation": 1,
  "Design": 2,
  "Revise & Approve": 3,
  "Development": 4,
  "Deployment & Final Approval": 5,
  "Complete Project": 6,
};

useEffect(() => {
  const unsubscribe = onSnapshot(collection(db, "projects"), async (snapshot) => {
    let projectsData = snapshot.docs.map((doc) => {
      let data = doc.data();

      // Convert Firestore Timestamps to strings if necessary
      if (data.start_date && typeof data.start_date.toDate === "function") {
        data.sortableDate = data.start_date.toDate(); // For sorting
        data.start_date = data.start_date.toDate().toLocaleDateString("en-US");
      }
      if (data.end_date && typeof data.end_date.toDate === "function") {
        data.end_date = data.end_date.toDate().toLocaleDateString("en-US");
      }

      return { id: doc.id, ...data };
    });

    // Include client names asynchronously
    projectsData = await Promise.all(
      projectsData.map(async (project) => {
        if (project.clientUID) {
          const clientName = await fetchUserName(project.clientUID);
          return { ...project, clientName: clientName };
        }
        return project;
      })
    );

    // Filter based on search criteria
    const filteredProjects = projectsData.filter(project => {
      return (!searchCriteria.name || project.project_name.toLowerCase().includes(searchCriteria.name.toLowerCase())) &&
             (!searchCriteria.client || project.clientName && project.clientName.toLowerCase().includes(searchCriteria.client.toLowerCase())) &&
             (!searchCriteria.status || project.status === searchCriteria.status);
    });

    // Sort based on sort criteria
    const sortedAndFilteredProjects = filteredProjects.sort((a, b) => {
      if (sortCriteria === 'newest') {
        return b.sortableDate - a.sortableDate;
      } else if (sortCriteria === 'oldest') {
        return a.sortableDate - b.sortableDate;
      } else if (sortCriteria === 'title') {
        return a.project_name.localeCompare(b.project_name);
      } else if (sortCriteria === 'clientName') {
        return a.clientName.localeCompare(b.clientName);
      } else if (sortCriteria === 'currentStage') {
        return (statusOrder[a.status] || 0) - (statusOrder[b.status] || 0);
      }
      return 0;
    });

    setSortedProjects(sortedAndFilteredProjects);
  });

  return () => unsubscribe();
}, [searchCriteria, sortCriteria]); // Ensure the effect is re-executed when search or sort criteria change

  

  // Fetch user name based on clientUID
  async function fetchUserName(clientUID) {
    const userRef = doc(db, "users", clientUID);
    const userSnap = await getDoc(userRef);
    console.log(userSnap.data);
    if (userSnap.exists()) {
      return `${userSnap.data().firstName} ${userSnap.data().lastName}`;
    } else {
      return "Unknown Client";
    }
  }

  useEffect(() => {
    const sortedArray = [...projects].sort((a, b) => {
      if (sortCriteria === 'newest') {
        // Compare using sortableDate
        return b.sortableDate - a.sortableDate;
      } else if (sortCriteria === 'oldest') {
        return a.sortableDate - b.sortableDate;
      } else if(sortCriteria === 'title'){
        return a.project_name.localeCompare(b.project_name);
      } else if(sortCriteria === 'clientName'){
        return a.clientName.localeCompare(b.clientName);
      } else if (sortCriteria === 'currentStage') {
        return statusOrder[a.status] - statusOrder[b.status];
      }
    });
  
    setSortedProjects(sortedArray);
  }, [sortCriteria, projects]); 

  console.log(projects);

  return (
    <Card>
      <CardHeader>
        {/* Search and Filter */}
        <ProjectSearch onSearch={handleSearch}/>
        <ProjectFilter onSortChange={handleSortChange} />
      </CardHeader>

    {sortedProjects.length > 0 ? (
      <CardBody style={{minHeight: "1000px"}}>
        <CardTitle>
          <h2>Project List Overview</h2>
        </CardTitle>

        {/* Projects Overview */}
        <AccordionTable
          columns={projectLabels}
          dataset={sortedProjects}
          DropdownComponent={ProjectListDropdown}
        />
      </CardBody>
    ) : (
      <CardBody style={{ margin: "1%", textAlign: "center", height: "1000px"}}>
        <h5>No Projects</h5>
      </CardBody>
    )}
    </Card>
  );
}

export default ProjectOverview;
