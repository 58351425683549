import React, { useState, useEffect } from "react";
import { collection, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { db } from "../../../firebase";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";

function UsersOverview() {
  const [users, setUsers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState({});

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "users"), (snapshot) => {
      const userData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        created_at: doc.data().created_at.toDate().toLocaleDateString("en-US"),
      }));
      setUsers(userData);
    });

    return () => unsubscribe();
  }, []);

  const toggleDropdown = (userId) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [userId]: !prevState[userId],
    }));
  };

  const updateRole = async (userId, newRole) => {
    await updateDoc(doc(db, "users", userId), {
      role: newRole,
    });
    console.log(`Role updated to ${newRole} for user ID: ${userId}`);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle><h2>User List Overview</h2></CardTitle>
      </CardHeader>
      <CardBody>
        <Table striped>
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th className="text-center">Role</th>
              <th className="text-center">Created At</th>
              <th className="text-right">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td >{user.email}</td>
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td className="text-center">{user.role}</td>
                <td className="text-center">{user.created_at}</td>
                <td style={{display: "flex", justifyContent: "right"}}>
                  <Dropdown isOpen={dropdownOpen[user.id] || false} toggle={() => toggleDropdown(user.id)} style={{width: "fit-content"}}>
                    <DropdownToggle caret color="info">
                      Change Role
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => updateRole(user.id, 'client')}>Client</DropdownItem>
                      <DropdownItem onClick={() => updateRole(user.id, 'admin')}>Admin</DropdownItem>
                      {/* Add more roles as necessary */}
                    </DropdownMenu>
                  </Dropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {users.length === 0 && <p style={{ textAlign: "center" }}>No Users Found</p>}
      </CardBody>
    </Card>
  );
}

export default UsersOverview;
