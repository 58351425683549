import React, { useState } from "react";
import { Button } from "reactstrap";

function AccordionTableItem({ data, columns, DropdownComponent }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <React.Fragment>
      <tr>
        {/* Table Data */}
        {columns.map((column) => (
          <td className={column.className}>{data[column.accessor]}</td>
        ))}
        <td className="text-right">
          {/* Dropdown Button */}
          <Button onClick={toggleDropdown} color="info">
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        </td>
      </tr>
      {/* Dropdown Content */}
      <tr>
        <td colSpan={columns.length + 1}>
          <div
            style={{
              height: isExpanded ? "500px" : "0",
              overflowY: "scroll",
              transition: "height 0.5s ease",
            }}
          >
            <DropdownComponent data={data}/>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default AccordionTableItem;
