import React from "react";
import "./StatusBar.css"; // Updated CSS file name

const StatusBar = ({ data }) => {
  var stage = 0;

  switch (data.status) {
    case "Discussion & Consultation":
      stage = 1;
      break;
    case "Design":
      stage = 2;
      break;
    case "Revise & Approve":
      stage = 3;
      break;
    case "Development":
      stage = 4;
      break;
    case "Deployment & Final Approval":
      stage = 5;
      break;
    case "Complete Project":
      stage = 6;
      break;
    default:
      stage = 1;
  }

  const stageNames = [
    "Discussion & Consultation",
    "Design",
    "Revise & Approve",
    "Development",
    "Deployment & Final Approval",
  ];

  const totalStages = 5;
  const stages = Array.from({ length: totalStages }, (_, i) => i + 1);

  const stageIcons = [
    "tim-icons icon-pencil",
    "tim-icons icon-vector",
    "tim-icons icon-refresh-02",
    "tim-icons icon-laptop",
    "tim-icons icon-cloud-upload-94",
  ];

  return (
    <div className="status-bar">
      {stages.map((s, index) => (
        <React.Fragment key={index}>
          <div
            className={`status-bar-stage ${
              stage > s ? "status-bar-completed" : ""
            } ${stage === s ? "status-bar-current" : ""} ${
              stage < s ? "status-bar-incomplete" : ""
            }`}
          >
            <div className="status-bar-icon">
              <i className={stageIcons[index]} />
            </div>
              <div className="status-bar-label">{stageNames[index]}</div>
          </div>
          {index < totalStages - 1 && (
            <div
              className={`status-bar-connector ${
                stage > s ? "status-bar-connector-completed" : ""
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default StatusBar;
