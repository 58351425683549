import React, { useState, useEffect } from "react";
import { db } from "../../../firebase";
import { collection, getDocs, addDoc, serverTimestamp } from "firebase/firestore";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

function CreateTicket() {
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [reason, setReason] = useState("");
  const [status, setStatus] = useState("Open"); // Default status
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedWebsiteId, setSelectedWebsiteId] = useState("");
  const [users, setUsers] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [dropdownOpenUser, setDropdownOpenUser] = useState(false);
  const [dropdownOpenWebsite, setDropdownOpenWebsite] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      setUsers(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    };

    const fetchWebsites = async () => {
      const querySnapshot = await getDocs(collection(db, "websites"));
      setWebsites(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })));
    };

    fetchUsers();
    fetchWebsites();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const website = websites.find(website => website.id === selectedWebsiteId);
      await addDoc(collection(db, "tickets"), {
        subject,
        description,
        reason,
        status,
        user_id: selectedUserId,
        email: users.find(user => user.id === selectedUserId)?.email,
        website_id: selectedWebsiteId,
        website_name: website ? website.website_name : "",
        creation_date: serverTimestamp(),
      });
      // Reset form or provide user feedback
      console.log("Ticket created successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const toggleDropdownWebsite = () => setDropdownOpenWebsite(prevState => !prevState);

  return (
    <Card>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup>
                <Label for="subject">Ticket Subject</Label>
                <Input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="reason">Reason</Label>
                <Input
                  type="text"
                  name="reason"
                  id="reason"
                  placeholder="Reason"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              placeholder="Description"
              value={description}
              style={{ paddingLeft: "10px" }}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </FormGroup>
          <Row form>
            <Col md={6}>
            <FormGroup>
                <Label for="userEmail">User</Label>
                <Input
                  type="select"
                  name="user"
                  id="user"
                  className="form-control"
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  required
                >
                  <option value="">Select a User</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>{user.email}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
            <FormGroup>
                <Label for="website">Website</Label>
                <Input
                  type="select"
                  name="website"
                  id="website"
                  className="form-control"
                  onChange={(e) => setSelectedWebsiteId(e.target.value)}
                  required
                >
                  <option value="">Select a Website</option>
                  {websites.map(website => (
                    <option key={website.id} value={website.id}>{website.website_name}</option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Button color="info" type="submit" style={{ marginTop: "20px" }}>
            Create New Ticket
          </Button>
        </form>
      </CardBody>
    </Card>
  );
}

export default CreateTicket;
